<template>
  <SedeEstado
    titulo="Reportes de Inventario - Catalogo"
    :servicioGetReporte="servicioGetReporte"
    :servicioGetReportePdf="servicioGetReportePdf"
    :componenteListado="componenteListado"
    tipo="catalogo"
  />
</template>
  
<script>
import SedeEstado from "../components/SedeEstado.vue";
import ReporteInventarioService from "../services";

export default {
  name: "ReporteInventarioCatalogoLayout",
  components: {
    SedeEstado,
  },
  data() {
    return {
      componenteListado: "ListadoCatalogo",
    };
  },
  methods: {
    servicioGetReporte: ReporteInventarioService.getReporteInventarioCatalogo,
    servicioGetReportePdf:
      ReporteInventarioService.getReporteInventarioCatalogoPdf,
  },
};
</script>
  